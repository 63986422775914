<template>
<div>
    <van-nav-bar :left-arrow="true" left-text="返回" title="报价自查" fixed :placeholder="true" @click-left="goBack" />
    <!-- <div class="home-wrap" >
      <div class="main-card-wrap" v-for="item in tableData" >
        <div style="border-bottom: 1px  dashed #ccc;margin-bottom: 10px">
          <van-cell center :title="'('+item.id+') '+item.name"  >
            <template #right-icon>
              <van-switch v-model="item.status == 1" size="24" @change="setStatus(item)"/>
            </template>
          </van-cell>
          <van-collapse v-model="activeNames">
            <van-collapse-item title="城市" :name="item.id">{{ item.city_desc?item.city_desc:'不限' }}</van-collapse-item>
            <van-collapse-item title="影院" :name="item.id">{{ item.cinema_desc?item.cinema_desc:'不限' }}</van-collapse-item>
          </van-collapse>
        </div>
      </div> -->
    <!-- </div> -->
    <van-cell-group style="margin: 10px">
        <van-row class="custom-text">
            <van-cell :title="name" label="仅统计该符合该报价设置的近100单数据，方便自查规则设置是否有问题">
                <template #right-icon>
                    <van-cell class="custom-input">
                        <template>
                            <span style="font-size: 12px; color: red; font-weight: bold;">中标率: {{ rate }}</span>
                        </template>
                    </van-cell>
                </template>
            </van-cell>
        </van-row>
        <van-row type="flex" justify="space-between" style="font-size: 12px;padding: 10px;background-color: rgb(230, 197, 174);display: flex; align-items: center;" @click="bidDetail(config)">
            <van-col span="6">报价数：<span style="color:green">{{ bidList.length }}</span></van-col>
            <van-col span="6" style="text-align: right;">不可报价数：<span style="color:red">{{ unbidList.length }}</span></van-col>
        </van-row>
    </van-cell-group>
    <van-tabs v-model="activeIndex" style="margin: 10px">
        <van-tab :title="activeNames[index-1]" v-for="index in 2" :key="index" />
    </van-tabs>
    <div v-if="activeIndex == 0" style="height: 500px; overflow: scroll;">
        <van-list v-model="loading" :error.sync="error" error-text="请求失败，点击重新加载" :offset="50">
            <div v-for="(bid, index) in bidList" :key="index" style=" font-size: small;">
                <van-cell-group style="margin: 10px 10px 20px 10px">
                    <van-row class="custom-text">
                        <van-cell :title="bid.cinema_name" :title-style="'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'">
                            <template>
                                <span style="font-size: 12px; color: navy; font-weight: bold;">{{bid.city_name}}</span>
                            </template>
                        </van-cell>
                    </van-row>
                    <van-row style="font-size: 12px;padding: 10px;background-color: rgb(236, 225, 218);">
                        <van-row type="flex" justify="space-between" style="display: flex; align-items: center;">
                            <van-col span="6">{{ bid.hall_name }}</van-col>
                            <van-col span="6" style="text-align: right;">{{ bid.movie_name }}</van-col>
                        </van-row>
                        <van-row type="flex" justify="space-between" style="display: flex; align-items: center; margin-top: 5px;">
                            <van-col span="6">座位数：{{ bid.quantity }}</van-col>
                            <van-col span="8">市场价：￥{{ bid.channel_price }}</van-col>
                            <van-col span="8" style="text-align: right;">限价：￥{{ bid.order_price }}</van-col>
                        </van-row>
                    </van-row>
                </van-cell-group>
            </div>
        </van-list>
    </div>
    <div v-if="activeIndex == 1" style="height: 500px; overflow: scroll">
        <van-list v-model="loading" error-text="请求失败，点击重新加载" :offset="50" :finished="finished">
            <div v-for="(bid, index) in unbidList" :key="index" style="font-size: small;">
                <van-cell-group style="margin: 10px 10px 20px 10px">
                    <van-row class="custom-text">
                        <van-cell :title="bid.cinema_name" :title-style="'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'">
                            <template>
                                <span style="font-size: 12px; color: navy; font-weight: bold;">{{bid.city_name}}</span>
                            </template>
                        </van-cell>
                    </van-row>
                    <van-row style="font-size: 12px;padding: 10px;background-color: rgb(236, 225, 218);">
                        <van-row type="flex" justify="space-between" style="display: flex; align-items: center;">
                            <van-col span="6">{{ bid.hall_name }}</van-col>
                            <van-col span="6" style="text-align: right;">{{ bid.movie_name }}</van-col>
                        </van-row>
                        <van-row type="flex" justify="space-between" style="display: flex; align-items: center; margin-top: 5px;">
                            <van-col span="6">座位数：{{ bid.quantity }}</van-col>
                            <van-col span="8">市场价：￥{{ bid.channel_price }}</van-col>
                            <van-col span="8" style="text-align: right;">限价：￥{{ bid.order_price }}</van-col>
                        </van-row>
                    </van-row>
                </van-cell-group>
            </div>
        </van-list>
    </div>
</div>
</template>

<script>
import Api from '@/api-service'
export default {
    name: "Rule-Detail",
    data() {
        return {
            bidList: [],
            unbidList: [],
            activeNames: ['可以报价', '不可报价'],

            activeIndex: 0, // 当前激活的标签
            configId: 0,
            name:'',
            loading: false,
            rate:'',
        }
    },
    created() {
        this.configId = this.$route.query.id;
        this.name = this.$route.query.name;
        this.fetchData();
    },
    methods: {
        fetchData() {
            // 获取该报价方案的所有数据
            let data = {
                src: 1,
                config_id: this.configId,
            }
            Api.bidRecord(data).then(res => {
                this.loading = false;
                this.finished = true
                this.rate = res.rate
                res.list.forEach(item => {
                    // this.unbidList.push(item)
                    // this.bidList.push(item)
                    if (item.status == 7) {
                        this.unbidList.push(item)
                    } else {
                        this.bidList.push(item)
                    }
                })

                console.log(this.unbidList)
                console.log(this.bidList)

            }).catch(res => {
                this.loading = false;
            })
        },
        setStatus(item) {
            let data = {
                id: item.id,
                status: item.status == 1 ? 2 : 1
            }

            Api.setCinemaRuleStatus(data).then(res => {
                this.$notify({
                    type: 'success',
                    message: '操作成功'
                });
                this.fetchData();
            })
        },
        goBack() {
            this.$router.go(-1);
        },
        status(item) {
            return item.status == 1 ? true : false;
        }
    }
}
</script>

<style lang="scss" scoped>
.home-wrap {
    height: 100%;
    background-color: #f3f3f3;

    .main-card-wrap {
        background-color: white;
        -moz-box-shadow: 2px 2px 3px #ccc;
        -webkit-box-shadow: 2px 2px 3px #ccc;
        box-shadow: 2px 2px 3px #ccc;

        .left {
            .title {
                font-size: 16px;
            }

            .btn {
                height: 34px;
                width: 120px;
                color: white;
                margin-top: 6px;
                border-radius: 4px;
                background-color: #8565bd;
            }
        }

        .right {
            margin-right: 8px
        }
    }
}

.custom-input {
    width: 30%;
    /* 或者根据需要设置具体宽度 */
    height: 100%;
    /* 或者根据需要设置具体高度 */
}

// .custom-input .van-field__control {
//   /* 自定义输入框内部样式 */
//   text-align: right;
//   height: 100%;
//   font-size: 14px; /* 根据需要调整 */
// }

// .custom-input .van-field__right-icon {
//   /* 自定义右侧图标区域样式 */
//   display: none; /* 如果不需要右侧图标，可以隐藏 */
// }
</style>
